@use '../../app/App.module.scss' as *;

.wrapper {
    display: grid;
    grid-template-areas:
        'main'
        'footer';
    grid-template-rows: 1fr auto;
    min-height: 100vh;
}

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 92px;
    padding-bottom: 92px;

    @media #{media-query('tablet')} {
        padding-top: 170px;
        padding-bottom: 90px;
    }
}

.section {
    max-width: 448px;
    margin: 0 auto;
    text-align: center;
}

.image {
    width: 100%;
    margin-bottom: 2px;

    @media #{media-query('tablet')} {
        margin-bottom: 32px;
    }
}

.text {
    margin: 0;
    margin-bottom: 36px;
    font-family: $text-font-family;
    font-size: 24px;
    font-weight: 600;
    color: $grey-color;
}

.link {
    min-width: 222px;
    padding: 12px 24px;
    border-radius: 6px;
    font-weight: 500;
    color: inherit;
    text-decoration: none;
    background-color: $light-card-color;
    transition: all 300ms;

    &:hover {
        background-color: #dedee3;
        color: #4d4d4d;
        transition: all 300ms;
    }
}

.footer-wrapper {
    max-height: 472px;

    @media #{media-query('tablet')} {
        max-height: 392px;
    }
}
