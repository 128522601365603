@use '../../app/App.module.scss' as *;

.app-card {
    @include card;
    position: relative;
}

.app-card--dark {
    @include card-dark;

    & .slogan {
        color: $white-color;
    }

    & .short-description {
        color: $white-color;
    }

    & .ios-link {
        order: 1;
    }

    & .macos-link {
        order: 2;
    }
}

.info {
    margin-bottom: 48px;

    @media #{media-query('tablet')} {
        display: flex;
        justify-content: space-between;
        column-gap: 48px;
    }
}

.icon {
    width: 144px;
    aspect-ratio: 1 / 1;
    margin-bottom: 28px;

    @media #{media-query('tablet')} {
        margin: 0;
    }
}

.title {
    margin: 0;
    font-family: $title-font-family;
    font-size: 48px;
    line-height: 48px;
    font-weight: 700;
    color: $accent-color;

    @media #{media-query('desktop-sm')} {
        font-size: 64px;
        line-height: 64px;
    }

}

.slogan {
    margin: 0;
    margin-bottom: 16px;
    font-family: $title-font-family;
    font-size: 48px;
    line-height: 1;
    font-weight: 700;
    color: $main-hover-text-color;

    @media #{media-query('desktop-sm')} {
        font-size: 64px;
        line-height: 64px;
    }
}

.short-description {
    display: block;
    margin: 0;
    margin-bottom: 16px;
    font-family: $title-font-family;
    font-size: 24px;
    line-height: 32px;
}

.description {
    margin: 0;
    margin-bottom: 32px;
    font-family: $text-font-family;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.45px;
    color: $main-text-color;
}

.button-group {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media #{media-query('tablet')} {
        flex-direction: row;
    }
}

.app-link {
    display: block;
}

.app-link > :is(svg, img, picture) {
    opacity: 1;

    @media (hover: hover) {
        &:hover {
            opacity: 0.8;
        }
    }
}

.ios-link {
    width: 176px;
    height: 64px;
    order: 2;
}

.macos-link {
    width: 258px;
    height: 64px;
    order: 1;
}
