@use '../../app/App.module.scss' as *;

.visually-hidden {
    @include visually-hidden;
}

.card {
    @include card;
    @include card-dark;

    position: relative;
    background-color: $form-card-color;
    overflow: hidden;
    z-index: $basic-layer + 1;

    @media #{media-query('mobile')} {
        padding-inline: 80px;
    }

    @media #{media-query('tablet')} {
        padding-inline: 0;
    }
}

.title {
    margin: 0 auto;
    margin-bottom: 32px;
    padding: 0;
    font-family: $title-font-family;
    font-weight: 700;
    font-size: 48px;
    line-height: 1;
    color: $white-color;

    @media #{media-query('tablet')} {
        margin-bottom: 48px;
        text-align: center;;
        font-size: 96px;
    }
}

.sent-text {
    margin: 0;
    margin-bottom: 32px;
    font-size: 18px;
    line-height: 24px;

    @media #{media-query('tablet')} {
        margin-bottom: 48px;
        text-align: center;
        font-size: 24px;
        line-height: 32px;
    }
}

.sent-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 32px;

    @media #{media-query('tablet')} {
        margin-bottom: 48px;
    }
}

.sent-image {
    display: block;
    object-fit: contain;
}

.sent-button {
    width: 100%;
    padding: 20px 40px;
    border: none;
    background-color: $white-color-10-opacity;
    border-radius: $extra-small-radius;
    font-family: $text-font-family;
    font-size: 24px;
    font-weight: 600;
    color: $white-color;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}

.form-wrapper {
    position: relative;
    margin: 0 auto;
    transition: all 300ms;

    @media #{media-query('tablet')} {
        max-width: 680px;
    }
}

.form-wrapper--shorten {
    height: 482px;
    transition: all 300ms;
}

.form {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin: 0 auto;

    @media #{media-query('tablet')} {
        max-width: 680px;
        row-gap: 32px;
    }
}

@keyframes hideForm {
    0% {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        visibility: hidden;
        transform: translateY(200px);
        opacity: 0;
    }
}

.form-hidden {
    pointer-events: none;
    animation: hideForm 400ms forwards;
}

.fields-wrapper {
    display: contents;

    @media #{media-query('tablet')} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 16px;
    }
}

.text {
    margin: 0;
    padding-top: 9px;
    margin-bottom: -17px;
    text-align: center;
    line-height: 24px;

    & > a {
        color: $accent-color;
        opacity: 0.7;
    }
}

.submit-button {
    width: 100%;
    padding: 18px 40px;
    border: none;
    border-radius: 8px;
    font-family: $text-font-family;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: $white-color;
    background-color: $accent-color;
    cursor: pointer;
    transition: all 300ms;

    &:disabled {
        color: $white-color-10-opacity;
        background-color: #1f2022;
        cursor: default;
    }

    &:hover {
        opacity: 0.8;
        transition: all 300ms;
    }
}

@keyframes showSentCard {
    0% {
        visibility: hidden;
        transform: translateY(0);
        opacity: 0;
        top: 0px;
    }

    100% {
        visibility: visible;
        transform: translateY(calc(-50% - 15px));
        top: 50%;
        opacity: 1;
    }
}

.sent-card {
    position: absolute;
    left: 0;
    right: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: $basic-layer + 1;
}

.sent-card-shown {
    position: absolute;
    pointer-events: all;
    animation: showSentCard 400ms forwards;
}
