/* VARIABLES */
$breakpoints: (
  'mobile-s': 414px,
  'mobile': 681px,
  'mobile-l': 768px,
  'mobile-xl': 960px,
  'tablet': 961px,
  'tablet-lg': 1100px,
  'desktop-sm': 1101px,
  'desktop': 1280px,
  'desktop-lg': 1440px,
);

/* FONTS */
$text-font-family: 'SF Pro Text', 'Inter', sans-serif;
$title-font-family: 'Raleway', 'Inter', sans-serif;

/* PAGE */
$max-page-width: 1280px;
$max-container-width: 1440px;

/* RADIUS */
$extra-small-radius: 8px;
$small-radius: 12px;
$medium-radius: 24px;
$large-radius: 48px;
$extra-large-radius: 64px;

/* LEVELS */
$basic-layer: 1;
$menu-layer: 10;
$modal-layer: 20;

/* COLORS */
$transparent: transparent;

$white-color: #ffffff;
$white-color-90-opacity: #ffffffe6;
$white-color-50-opacity: #ffffff80;
$white-color-30-opacity: #ffffff4d;
$white-color-20-opacity: #ffffff33;
$white-color-10-opacity: #ffffff1a;

$black-color: #000000;
$black-color-40-opacity: #00000066;
$black-color-20-opacity: #00000033;
$black-color-10-opacity: #0000001a;

$grey-color: #767676;
$grey-light-color: #ececec;
$grey-dark-color: #5a5a5a;
$grey-darker-color: #4d5055;

/* text */
$main-text-color: #a2a09f;
$main-text-color-10-opacity: #a2a09f1a;
$main-hover-text-color: #39393d;

/* accent */
$accent-color: #f75300;

/* controls */
$scrollbar-thumb-color: #8f8f91;
$caret-color: #0066ff;

/* errors */
$border-error-color: #ff6b6b;
$text-error-color: #ff6b6b4d;

/* cards */
$light-card-color: #f5f5f7;
$dark-card-color: #0e0e10;
$form-card-color: #111214;
$box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.16);

/* MIXINS/FUNCTIONS */
@mixin visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

@mixin card {
    padding: 80px 24px;
    background-color: $light-card-color;
    color: $main-hover-text-color;
    border-radius: $large-radius;

    @media #{media-query('mobile')} {
        padding: 80px;
    }

    @media #{media-query('tablet')} {
        border-radius: $extra-large-radius;
    }
}

@mixin card-dark {
    background-color: $dark-card-color;
    color: $main-text-color;
}

@mixin background-blur {
    backdrop-filter: blur(10px);
}

@mixin close-button-animation-start {
    transform: scale(0);
    transition: transform 300ms 300ms ease-out;

    & path:nth-child(1) {
        opacity: 0;
        transform: translate(-15px, 15px);
        transition: opacity 1ms 300ms linear;
    }
}

@mixin close-button-animation-end {
    transform: scale(1);

    // Dot - Sliding to diagonal right with delay
    & path:nth-child(1) {
        opacity: 1;
        transform: translate(0);
        transition: transform 300ms 800ms ease-out;
    }
}

@function media-query($breakpoint) {
    $width: map-get($breakpoints, $breakpoint);
    @if $width {
      @return "only screen and (min-width: #{$width})";
    } @else {
      @warn "Unknown breakpoint: #{$breakpoint}";
      @return null;
    }
  }
