@use '../../../app/App.module.scss' as *;

$field-padding: 45px;
$float-padding: 35px;

.visually-hidden {
    @include visually-hidden;
}

.dropdown-wrapper {
    position: relative;
    min-height: 64px;
    z-index: $basic-layer + 1;
}

.dropdown-wrapper-button {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: transparent;
    border: none;
    color: inherit;
    z-index: $basic-layer + 2;
}

.dropdown-wrapper-icon {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    fill: #ffffff80;
    
}

.dropdown-wrapper-icon--has-error {
    fill: #ff6b6b80;
}

.dropdown {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    border: 1px solid $grey-darker-color;
    border-radius: $extra-small-radius;
    background-color: #1f2022;

    &--has-error {
        border-color: $border-error-color;
    }
}

.dropdown--open .field-wrapper {
    &::before {
        position: absolute;
        top: 55px;
        left: 16px;
        right: 16px;
        height: 1px;
        background-color: $grey-darker-color;
        z-index: $basic-layer + 2;
        content: '';
    }
}

.field-wrapper--has-placeholder::after {
    position: absolute;
    inset: 0;
    padding: 16px 40px 14px 45px;
    font-family: $text-font-family;
    font-size: 18px;
    line-height: 32px;
    color: $white-color-30-opacity;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
    content: attr(data-placeholder);

    @media #{media-query('tablet')} {
        font-size: 24px;
    }
}

.field-label {
    position: absolute;
    top: 16px;
    left: $field-padding - $float-padding;
    padding-inline: 5px;
    border-radius: $extra-small-radius;
    font-family: inherit;
    font-size: 18px;
    line-height: 32px;
    color: $white-color-30-opacity;
    transition: all 300ms;
    pointer-events: none;

    &--is-focused {
        top: 0;
        left: 11px;
        transform: translateY(-50%);
        font-size: 16px;
        line-height: 24px;
        color: $white-color-50-opacity;
        background-image: linear-gradient(to bottom, transparent 0px, transparent 11px,#1f2022 11px);
        transition: all 300ms;
        z-index: $basic-layer + 2;
    }

    &--has-error {
        color: $text-error-color;
        transition: all 300ms;
    }
}

.field {
    width: 100%;
    min-height: 64px;
    padding: 15px $field-padding;
    padding-right: 40px;
    border: none;
    border-radius: $extra-small-radius;
    font-family: $text-font-family;
    font-size: 18px;
    line-height: 32px;
    color: $white-color;
    caret-color: transparent;
    background-color: #1f2022;
    outline: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--is-focused {
        caret-color: $caret-color;
    }

    @media #{media-query('tablet')} {
        font-size: 24px;
    }
}

.field-flag {
    position: absolute;
    display: block;
    top: 32px;
    transform: translateY(-50%);
    left: 15px;
    font-size: 18px;

    &--inactive {
        opacity: 0.70;
    }
}

.country-wrapper {
    display: none;
}

.country-wrapper {
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 6px;
        background-color: $transparent;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: $scrollbar-thumb-color;
    }
}

.dropdown--open .country-wrapper {
    display: block;
}

.country-list {
    margin: 0;
    padding: 0;
    max-height: 132px;
    margin-left: 15px;
    margin-right: 8px;
    list-style-type: none;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-thumb-color $transparent;
    
    @media #{media-query('tablet')} {
        max-height: 264px;
    }
}

.country {
    position: relative;
    width: 100%;
    padding-block: 10px;
    padding-left: 30px;
    border: none;
    text-align: left;
    color: $white-color;
    background-color: transparent;
    font-size: 18px;
    line-height: 32px;
    cursor: pointer;

    @media #{media-query('tablet')} {
        font-size: 24px;
    }

    &::before {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        content: attr(data-text);
    }

    &--is-empty {
        padding-left: 0;
        color: $white-color-30-opacity;
        cursor: default;
    }

    &--active {
        font-weight: 800;
    }
}
