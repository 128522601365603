@font-face {
    font-family: 'Raleway';
    src: url('assets/fonts/Raleway-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('assets/fonts/Raleway-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text Regular'), local('SF Pro Text'), local('Inter'), local('sans-serif');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text Medium'), local('SF Pro Text'), local('Inter'), local('sans-serif');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text Semibold'), local('SF Pro Text'), local('Inter'), local('sans-serif');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

html {
    overscroll-behavior-y: none;
    scroll-behavior: smooth;

    /*     NOTE: May be used for header space compensation
    scroll-padding-top: 24px; */
}

.no-smooth-scroll {
    scroll-behavior: initial;
}

body {
    position: relative;
    margin: 0;
    font-family: 'SF Pro Text', 'Inter', sans-serif;
    color: #a2a09f;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.no-scroll {
    overflow: hidden;
    pointer-events: none;
    touch-action: none;
}

.modal--open {
    overflow: hidden;
    padding-right: calc(100vw - 100%);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
    box-sizing: border-box;
}
