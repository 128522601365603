@use '../../../app/App.module.scss' as *;

$card-radius: 32px;

.visually-hidden {
    @include visually-hidden;
}

.modal-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: $modal-layer;
    background-color: #11121480;

    &--is-open {
        display: flex;
    }
}

.card {
    @include card;

    & {
        max-width: 300px;

        padding: 24px;
        border-radius: $card-radius;
    }

    @media #{media-query('mobile')} {
        padding: 24px;
        border-radius: $card-radius;
    }
}

.title {
    margin: 0;
    margin-bottom: 8px;
    text-align: center;
    font-family: $title-font-family;
    font-size: 48px;
    line-height: 56px;
    font-weight: 700;
}

.text {
    margin: 0;
    margin-bottom: 24px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: $main-hover-text-color;
}

.image-wrapper {
    width: 252px;
    aspect-ratio: 252 / 103;
    margin-bottom: 24px;
}

.image {
    display: block;
    object-fit: cover;
    overflow: visible;
}

.button {
    width: 100%;
    padding: 12px 24px;
    border: none;
    background-color: $black-color-10-opacity;
    border-radius: $extra-small-radius;
    font-family: $text-font-family;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    color: $main-hover-text-color;
    cursor: pointer;
    transition: all 300ms;

    &:hover {
        opacity: 0.8;
        transition: all 300ms;
    }
}
