@use '../../app/App.module.scss' as *;

.carousel-wrapper {
    position: relative;
    overflow-x: clip;
    margin-inline: auto;
    touch-action: pan-y;
}

.carousel {
    position: relative;

    &::before,
    &::after {
        position: absolute;
        top: -15px;
        bottom: -15px;
        left: 0px;
        width: 20px;
        z-index: $basic-layer + 1;
        background: linear-gradient(to left, rgba(245, 245, 247, 0) 0%, rgba(245, 245, 247, 1) 100%);
        content: '';

        @media #{media-query('tablet')} {
            min-width: 88px;
        }
    }

    &::after {
        left: unset;
        right: 0px;
        background: linear-gradient(to right, rgba(245, 245, 247, 0) 0%, rgba(245, 245, 247, 1) 100%);
    }
}

.carousel--dark {
    &::before {
        background: linear-gradient(to left, rgba(14, 14, 16, 0) 0%, rgba(14, 14, 16, 1) 100%);
    }

    &::after {
        background: linear-gradient(to right, rgba(14, 14, 16, 0) 0%, rgba(14, 14, 16, 1) 100%);
    }
}

.carousel-side-controls {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10%;
    padding: 0;
    border: none;
    background-color: transparent;
    outline: none;
    z-index: $basic-layer + 2;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    @media #{media-query('mobile-l')} {
        width: 9%;
    }

    @media #{media-query('tablet')} {
        display: none;
        cursor: default;
        pointer-events: none;
    }

    &--vertical {
        width: 30%;
    }
}

.carousel-side-controls--left {
    left: -10px;
    transform: rotate(180deg);
}

.carousel-side-controls--right {
    right: -10px;
}

.carousel-inner {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    transition: transform 250ms linear;
}

.slide {
    flex: 0 0 80%;

    @media #{media-query('tablet')} {
        flex-basis: 65%;
    }

    @media #{media-query('desktop-sm')} {
        flex-basis: 95%;
    }

    @media #{media-query('desktop')} {
        flex-basis: 75%;
    }

    @media #{media-query('desktop-lg')} {
        flex-basis: 68%;
    }
}

.slide--vertical {
    flex: 0 0 39%;

    @media #{media-query('tablet')} {
        flex-basis: 50%;
    }

    @media #{media-query('desktop-sm')} {
        flex-basis: 40%;
    }

    @media #{media-query('desktop')} {
        flex-basis: 35%;
    }

    @media #{media-query('desktop-lg')} {
        flex-basis: 30%;
    }
}

.slide-button {
    border-radius: 16px;
    transform: scale(0.9);
    opacity: 0.5;
    overflow: hidden;
    pointer-events: none;
    transition: all 0.3s ease-in-out;

    @media #{media-query('mobile-s')} {
        border-radius: 20px;
    }

    @media #{media-query('mobile-l')} {
        border-radius: 38px;
    }

    @media #{media-query('mobile-xl')} {
        border-radius: 48px;
    }

    @media #{media-query('tablet')} {
        border-radius: 32px;
    }

    @media #{media-query('desktop')} {
        transform: scale(0.95);
    }

    &:disabled {
        cursor: not-allowed;
        pointer-events: none;
    }
}

.slide--vertical .slide-button {
    transform: scale(0.85);
}

.slide-button--active {
    pointer-events: initial;
    transform: scale(1.05);
    opacity: 1;
    transition: all 0.3s ease-in-out;

    @media #{media-query('desktop')} {
        transform: scale(1);
    }
}

.slide--vertical .slide-button--active {
    transform: scale(1.05);
}

.slide img {
    display: block;
    width: 100%;
}

.control-wrapper {
    position: absolute;
    top: 50%;
    display: none;
    width: fit-content;
    z-index: $basic-layer + 2;
    transform: translateY(-50%);

    @media #{media-query('tablet')} {
        display: block;
    }

    &--left {
        left: 0;
    }

    &--right {
        right: 0;
    }
}

.dots-wrapper {
    padding-top: 32px;
    padding-bottom: 6px;

    @media #{media-query('mobile-s')} {
        padding-bottom: 0px;
    }
}

.dots-wrapper--vertical {
    padding-bottom: 1px;
}

.visually-hidden {
    @include visually-hidden;
}

.slide-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: block;
    width: 100%;
    height: 100%;

    &:focus {
        outline: none;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }
}
