@use '../../../app/App.module.scss' as *;

.app-card {
    @include card;
    position: relative;
}

.illustration {
    position: absolute;
    z-index: $basic-layer + 2;

    & > img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: contain;
    }
}

.illustration--clock {
    top: -60px;
    right: -90px;
    width: 203px;
    height: 251px;

    @media #{media-query('mobile')} {
        right: -32px;
    }

    @media #{media-query('tablet')} {
        width: 268px;
        height: 331px;
        top: unset;
        bottom: 3px;
        right: -150px;
    }
}

.illustration--orange-ball {
    bottom: -100px;
    left: -140px;
    width: 218px;
    height: 218px;
    z-index: $basic-layer - 1;

    @media #{media-query('tablet')} {
        top: 400px;
        left: -118px;
        bottom: unset;
        z-index: $basic-layer + 1;
    }
}

.illustration--rocket {
    top: -48px;
    right: -103px;
    width: 243px;
    height: 315px;

    & > img {
        transform: scaleX(-1) rotate(9deg);
    }

    @media #{media-query('tablet')} {
        & > img {
            transform: unset;
        }

        top: 349px;
        left: -144px;
        right: unset;
    }
}

.illustration--zebra-ball {
    bottom: -121px;
    left: -135px;
    width: 209px;
    height: 209px;
    z-index: $basic-layer - 1;

    @media #{media-query('tablet')} {
        top: 450px;
        bottom: unset;
        left: unset;
        right: -118px;
        z-index: $basic-layer + 1;
    }
}

.illustration--black-ball {
    display: none;
    width: 86px;
    height: 86px;
    z-index: $basic-layer - 2;

    @media #{media-query('tablet')} {
        display: block;
        bottom: 329px;
        right: -44px;
    }
}

.illustration--cursor {
    top: -40px;
    right: -75px;
    width: 243px;
    height: 315px;

    & > img {
        transform: scaleX(-0.9) rotate(9deg);
    }

    @media #{media-query('mobile')} {
        right: -20px;
    }

    @media #{media-query('tablet')} {
        & > img {
            transform: unset;
        }

        top: 320px;
        right: unset;
        left: -110px;
    }
}

.illustration--blue-ball {
    top: 809px;
    right: -73px;
    width: 118px;
    height: 118px;

    @media #{media-query('tablet')} {
        width: 151px;
        height: 151px;
        top: 130px;
        right: -50px;
    }
}

.illustration--book {
    top: -43px;
    right: -121px;
    width: 292px;
    height: 280px;

    @media #{media-query('tablet')} {
        right: -172px;
    }
}

.illustration--double-ball {
    display: none;
    width: 175px;
    height: 191px;

    @media #{media-query('tablet')} {
        display: block;
        top: 349px;
        left: -67px;
    }
}

.illustration--pink-ball  {
    bottom: -86px;
    right: -47px;
    width: 144px;
    height: 144px;
    z-index: $basic-layer - 1;
}

.app-card--dark {
    @include card-dark;

    & .slogan {
        color: $white-color;
    }

    & .short-description {
        color: $white-color;
    }

    & .ios-link {
        order: 1;
    }

    & .macos-link {
        order: 2;
    }
}

.info {
    margin-bottom: 48px;

    @media #{media-query('tablet')} {
        display: flex;
        justify-content: space-between;
        column-gap: 48px;
    }
}

.icon {
    margin-bottom: 28px;

    @media #{media-query('tablet')} {
        margin: 0;
    }
}

.title {
    margin: 0;
    font-family: $title-font-family;
    font-size: 48px;
    line-height: 48px;
    font-weight: 700;
    color: $accent-color;
}

.slogan {
    margin: 0;
    margin-bottom: 16px;
    font-family: $title-font-family;
    font-size: 48px;
    line-height: 1;
    font-weight: 700;
    color: $main-hover-text-color;
}

.short-description {
    display: block;
    margin: 0;
    margin-bottom: 16px;
    font-family: $title-font-family;
    font-size: 24px;
    line-height: 32px;
}

.description {
    margin: 0;
    margin-bottom: 32px;
    font-family: $text-font-family;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.45px;
    color: $main-text-color;
}

.button-group {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media #{media-query('tablet')} {
        flex-direction: row;
    }
}

.app-link {
    display: block;
    opacity: 1;
    transition: opacity 200ms;

    &:hover {
        opacity: 0.8;
        transition: opacity 200ms;
    }
}

.ios-link {
    width: 176px;
    height: 64px;
    order: 2;
}

.macos-link {
    width: 258px;
    height: 64px;
    order: 1;
}
