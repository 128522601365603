@use '../../../app/App.module.scss' as *;

$field-padding: 16px;
$float-padding: 5px;

::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    position: absolute;
    right: 0;
 }

.visually-hidden {
    @include visually-hidden;
}

.field-wrapper {
    position: relative;
}

.field-label {
    position: absolute;
    top: 16px;
    left: $field-padding - $float-padding;
    padding-inline: 5px;
    border-radius: $extra-small-radius;
    font-family: inherit;
    font-size: 18px;
    line-height: 32px;
    color: $white-color-30-opacity;
    transition: all 300ms;
    pointer-events: none;

    &--is-focused {
        top: 0;
        left: 11px;
        transform: translateY(-50%);
        font-size: 16px;
        line-height: 24px;
        color: $white-color-50-opacity;
        background-image: linear-gradient(to bottom, transparent 0px, transparent 12px,#1f2022 12px);
        background-position: center;
        transition: all 300ms;
    }

    &--has-error {
        color: $text-error-color;
        transition: all 300ms;
    }
}

.field {
    width: 100%;
    min-height: 64px;
    padding: 15px $field-padding;
    border: 1px solid $grey-darker-color;
    border-radius: $extra-small-radius;
    font-family: inherit;
    font-size: 18px;
    line-height: 32px;
    color: $white-color;
    caret-color: transparent;
    background-color: #1f2022;
    outline: none;

    &--is-focused {
        caret-color: $caret-color;
        transition: all 1ms 50ms;
    }

    &--textarea {
        min-height: 180px;
        resize: none;
    }

    &--has-error {
        border-color: $border-error-color;
    }

    @media #{media-query('tablet')} {
        font-size: 24px;
    }
}
