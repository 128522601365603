@use '../../app/App.module.scss' as *;

.card {
    @include card;

    position: relative;
    overflow: hidden;
    z-index: $basic-layer + 1;

    @media #{media-query('mobile')} {
        padding-inline: 24px;
    }

    @media #{media-query('tablet')} {
        padding-inline: 0px;
    }
}

.card::before {
    position: absolute;
    top: -170px;
    left: -670px;
    width: 1883px;
    aspect-ratio: 1883 / 1707;
    background-image: url('../../assets/images/background_logos.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: $basic-layer;
    content: '';

    @media #{media-query('mobile-s')} {
        top: -170px;
        left: -590px;
    }

    @media #{media-query('mobile')} {
        top: -290px;
        left: -360px;
    }

    @media #{media-query('tablet')} {
        top: -360px;
        left: -220px;
    }

    @media #{media-query('tablet-lg')} {
        top: -135px;
        left: -305px;
    }

    @media #{media-query('desktop')} {
        top: -135px;
        left: -105px;
    }
}

.title {
    position: relative;
    margin: 0 auto;
    margin-bottom: 72px;
    padding-left: 8px;
    font-family: $title-font-family;
    font-weight: 700;
    font-size: 48px;
    line-height: 1;
    z-index: $basic-layer + 1;

    @media #{media-query('tablet')} {
        margin-bottom: 24px;
        padding-left: 80px;
        font-size: 96px;
    }
}

.text {
    position: relative;
    margin: 0 auto;
    margin-bottom: 72px;
    padding-top: 339px;
    font-size: 18px;
    line-height: 32px;
    color: $main-text-color;
    background-image: url('../../assets/images/unites_image_1.png');
    background-size: 250px 315px;
    background-position: top center;
    background-repeat: no-repeat;
    z-index: $basic-layer + 1;

    @media #{media-query('mobile')} {
        padding-inline: 56px;
    }

    @media #{media-query('tablet')} {
        display: flex;
        align-items: center;
        min-height: 470px;
        margin-bottom: 24px;
        padding: 0;
        padding-inline: 374px 80px;
        font-size: 24px;
        background-position: center left;
        background-size: 374px 470px;
    }
}

.text--reversed {
    padding-top: 372px;
    margin-bottom: 0;
    background-image: url('../../assets/images/unites_image_2.png');
    background-position: top 32px center;

    @media #{media-query('tablet')} {
        min-height: 472px;
        padding-block: 0;
        padding-inline: 80px 340px;
        background-position: center right;
    }
}
