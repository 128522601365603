@use '../../app/App.module.scss' as *;

.visually-hidden {
    @include visually-hidden;
}

.carousel-button {
    width: 56px;
    height: 56px;
    border: none;
    border-radius: 50%;
    background-color: $black-color-40-opacity;
    background-image: url('../../assets/icons/slider_arrow_icon.svg');
    background-repeat: no-repeat;
    background-size: center;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    &--reversed {
        transform: rotate(180deg);
  }

    &:hover {
        background-color: $black-color;
  }
}
