@use '../../app/App.module.scss' as *;

$full-slider-resolution: 'tablet';

.modal-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $black-color;
    z-index: $modal-layer;
}

.modal-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: $black-color-40-opacity;
    z-index: $modal-layer + 1;
}

.header-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 692px;
    margin: 0 auto;
    padding: 16px;

    @media #{media-query($full-slider-resolution)} {
        justify-content: center;
        max-width: 1248px;
        padding-block: 30px;
    }
}

.tabs-wrapper {
    display: none;

    @media #{media-query($full-slider-resolution)} {
        display: block;
    }
}

.icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;

    @media #{media-query($full-slider-resolution)} {
        position: absolute;
        right: 0;
        transform: translateX(-50%);
    }
}

@keyframes showIcon {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

@keyframes moveDot {
    to {
        opacity: 1;
        transform: translate(0);
    }
}

.close-icon {
    width: 32px;
    height: 32px;
    position: relative;
    animation: showIcon 300ms ease-out;

    path:nth-child(1) {
        opacity: 0;
        transform: translate(-15px, 15px);
        animation: moveDot 200ms 300ms linear;
        animation-fill-mode: forwards;
    }

    & > :not(:first-child) {
        opacity: 1;
        fill: white;
    }
}

.modal-body {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1248px;
    height: 100%;
    margin: 0 auto;
    padding-top: 30px;

    @media #{media-query($full-slider-resolution)} {
        padding: 96px;
        padding-bottom: 32px;
    }
}

.image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 100%;
    overflow: hidden;
}

.modal-image-button {
    display: flex;
    padding: 0;
    border: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: pointer;
}

.modal-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.control-wrapper {
    position: absolute;
    top: 50%;
    display: none;
    width: fit-content;
    transform: translateY(-50%);

    @media #{media-query($full-slider-resolution)} {
        display: block;
    }

    &--left {
        margin-left: 60px;
        left: 0;
    }

    &--right {
        margin-right: 60px;
        right: 0;
    }
}
