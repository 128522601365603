@use '../../app/App.module.scss' as *;

.visually-hidden {
    @include visually-hidden;
}

.menu-body-wrapper,
.menu-footer {
    @include background-blur;
    background-color: $white-color-90-opacity;
    z-index: $menu-layer;
}

.menu-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    min-height: 80px;
    padding: 24px;
    overflow: hidden;
    border-top-left-radius: $medium-radius;
    border-top-right-radius: $medium-radius;

    &--is-hidden {
        transform: translateY(100%);
        transition: transform 0.5s;
    }
}

.menu-footer--is-visible {
    transform: translateY(0);
    transition: transform 600ms ease-out 300ms;

    @media #{media-query('tablet')} {
        display: none;
    }
}

.menu-footer--is-open {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    & .logo-wrapper {
        left: 50%;
        transform: translateX(-50%);
        transition: all 300ms linear;
    }

    & .open-button {
        left: 50%;
        transition: left 300ms linear;
    }
}

.logo-wrapper {
    position: absolute;
    left: 24px;
    width: 110px;
    aspect-ratio: 110 / 32;
    transition: all 300ms linear;

    & > * {
        display: block;
        width: 100%;
    }
}

.menu-body-wrapper {
    visibility: hidden;
    position: fixed;
    top: 0;
    bottom: 80px;
    left: 0;
    right: 0;
    display: flex;
    border-top-left-radius: $medium-radius;
    border-top-right-radius: $medium-radius;
    opacity: 0.3;
    overflow-y: auto;
    transform: translateY(100%);
    transition: transform 300ms, opacity 300ms, visibility 300ms, border-radius 300ms;

    @media #{media-query('desktop-sm')} {
        display: none;
    }
}

.menu-body-wrapper--is-open {
    visibility: visible;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    opacity: 1;
    transform: translateY(0);
    transition: transform 300ms, opacity 300ms ease-in, visibility 300ms, border-radius 300ms;
}

.menu-body {
    width: 100%;
    height: 570px;
    margin-block: auto;
    padding: 70px 16px;
}

.app-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0;
}

.app-caption {
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
}

.app-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.app-link {
    display: block;
    width: 72px;
    aspect-ratio: 1 / 1;
}

.menu-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-bottom: 8px;
    padding: 0;
    list-style-type: none;
}

.menu-link {
    display: block;
    min-width: 300px;
    padding: 16px 24px;
    text-align: center;
    font-size: 24px;
    color: inherit;
    text-decoration-line: none;
    text-transform: uppercase;
}

.open-button,
.close-button {
    position: relative;
    border: none;
    background-color: transparent;
    cursor: pointer;
    overflow: hidden;
}

.open-button {
    padding: 0;
    width: 32px;
    height: 32px;
    left: 0;
    transition: left 300ms linear;
}

.open-button svg {
    & rect {
        transition: transform 300ms 100ms ease-out;
    }

    // Dot - Sliding to left with delay
    & rect:nth-child(2) {
        opacity: 1;
        transition: transform 300ms 500ms ease-in, opacity 1ms 300ms linear;
    }
}

.close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 72px;
    min-height: 72px;
    aspect-ratio: 1 / 1;
    margin: 0 auto;
    border: 3px solid $main-text-color-10-opacity;
    border-radius: 50%;

    & > * {
        width: 32px;
        height: 32px;
    }
}

.close-button svg {
    @include close-button-animation-start;
}

.close-button--activated svg {
    @include close-button-animation-end;
}

.open-button--activated svg {
    // Common transition speed
    & rect {
        transition: transform 300ms ease-in;
    }

    // Dot - Sliding to right with delay
    & rect:nth-child(2) {
        opacity: 0;
        transform: translateX(10px);
        transition: transform 100ms ease-in, opacity 1ms linear;
    }

    // Top rect - Sliding to right
    & rect:nth-child(3) {
        transform: scaleX(0.5) translateX(200px);
    }

    // Middle rect - Sliding to left
    & rect:nth-child(4) {
        transform: scaleX(0.5) translateX(-200px);
    }

    // Bottom rect - Sliding to right
    & rect:nth-child(1) {
        transform: scaleX(0.5) translateX(200px);
    }
}
