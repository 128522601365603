@use '../../app/App.module.scss' as *;

.header-wrapper {
    display: none;

    @media #{media-query('tablet')} {
        display: block;
    }
}

.page-wrapper {
    display: grid;
    grid-template-areas:
        'main'
        'footer';
    grid-template-rows: 1fr auto;
    min-height: 100vh;

    @media #{media-query('tablet')} {
        padding-top: 80px;
    }
}

.main {
    max-width: $max-container-width;
    margin: 0 auto;
    padding: 80px 24px;
    color: $black-color;

    @media #{media-query('tablet')} {
        padding: 80px;
    }
}

.title,
.subtitle {
    margin: 0;
    font-family: $title-font-family;
}

.title {
    margin-bottom: 16px;
    font-size: 48px;
    font-weight: 700;

    @media #{media-query('tablet')} {
        font-size: 96px;
    }
}

.subtitle {
    margin-block: 40px;
    padding: 0;
    font-size: 18px;
    font-weight: 700;
}

.paragraph {
    margin-block: 20px;
    font-size: 18px;
    line-height: 32px;
}

.line {
    margin-block: 20px;
}

.link {
    font-size: 18px;
    text-decoration-line: none;
    color: $accent-color;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;

    &:hover{
        text-decoration-line: underline;
    }
}
