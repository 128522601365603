@use '../../app/App.module.scss' as *;

$bg-image-size-mobile: 1084px;
$bg-image-size-desktop: 1619px;
$bg-top-position-mobile: -745px;
$bg-top-position-desktop: -515px;
$bg-left-position-desktop: 564px;

.welcome {
    pointer-events: none;
}

.container {
    position: relative;
    max-width: $max-container-width;
    min-height: 638px;
    margin: 0 auto;
    padding-inline: 24px;
    padding-block: 294px 128px;

    @media #{media-query('mobile')} {
        padding-inline: 80px;
        padding-bottom: 160px;
    }

    @media #{media-query('tablet')} {
        min-height: 812px;
        padding-block: 302px 221px;
        pointer-events: initial;
    }
}

.title {
    position: relative;
    font-family: $title-font-family;
    font-size: 48px;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    margin-bottom: 24px;
    color: $main-hover-text-color;
    z-index: $basic-layer + 1;

    & > span {
        color: $accent-color;
    }

    @media #{media-query('tablet')} {
        font-size: 96px;
        margin-bottom: 32px;
    }
}

.lead {
    position: relative;
    font-family: $title-font-family;
    font-size: 20px;
    line-height: 32px;
    margin: 0;
    color: $main-hover-text-color;
    z-index: $basic-layer + 1;

    @media #{media-query('tablet')} {
        font-size: 24px;
    }
}


.image-wrapper {
    position: absolute;
    top: $bg-top-position-mobile;
    left: calc(50% - $bg-image-size-mobile / 2);
    transition: transform 0.2s linear;
    pointer-events: none;

    @media #{media-query('tablet')} {
        top: $bg-top-position-desktop;
        left: $bg-left-position-desktop;
        width: $bg-image-size-desktop;
    }
}

.image {
    display: block;
    width: $bg-image-size-mobile;
    height: auto;
    object-fit: contain;

    @media #{media-query('tablet')} {
        width: $bg-image-size-desktop;
    }
}
