@use '../../../app/App.module.scss' as *;

.visually-hidden {
    @include visually-hidden;
}

.app-fieldset {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    margin: 0;
    padding: 0;
    border: none;

    @media #{media-query('tablet')} {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: auto auto;
        gap: 10px;
    }
}

.app-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 16px 14px;
    border-radius: 8px;
    font-size: 16px;
    color: $white-color-50-opacity;
    background-color: $white-color-10-opacity;
    cursor: pointer;
    transition: all 300ms;

    &:hover {
        background-color: $white-color-20-opacity;
        transition: all 300ms;
    }

    &:nth-of-type(5) {
        grid-column: span 2;
    }

    @media #{media-query('tablet')} {
        padding: 12px 25px;
        font-size: 24px;
        line-height: 32px;
        grid-column: span 2;

        &:nth-of-type(4) {
            grid-column: span 3;
        }

        &:nth-of-type(5) {
            grid-column: span 3;
        }
    }
}

.app-button--active {
    color: $white-color;
    background-color: $accent-color;
    transition: all 300ms;

    &:hover {
        background-color: $accent-color;
    }
}

.app-button-icon {
    width: 24px;
    aspect-ratio: 1 / 1;
}
