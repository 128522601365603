@use '../../app/App.module.scss' as *;

.tabs {
    display: flex;
    column-gap: 8px;
    justify-content: center;
    margin: 0;
    margin-inline: auto;
    padding: 0;
    list-style-type: none;
}

.tab {
    width: 12px;
    height: 4px;
    background-color: $main-text-color;
    border-radius: 5px;
    opacity: 30%;
    transition: width 0.3s;

    &--active {
        width: 24px;
        background: $accent-color;
        opacity: 100%;
  }
}

.tab button {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    color: transparent;
    background-color: transparent;
    cursor: pointer;
}