@use '../../app/App.module.scss' as *;

$submenu-width: 300px;
$submenu-arrow-width: 20px;
$submenu-arrow-height: 8px;

.header {
    @include background-blur;

    position: absolute;
    display: none;
    width: 100%;
    padding-inline: 80px;
    background-color: $white-color-90-opacity;
    z-index: $menu-layer;

    @media #{media-query('tablet')} {
        display: block;
    }
}

@keyframes showFixedHeader {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }

}

.header--is-fixed{
    @media #{media-query('tablet')} {
        position: fixed;
        animation: showFixedHeader 200ms forwards;
    }
}

.header-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: $max-page-width;
    min-height: 80px;
    margin: 0 auto;
}

.logo-wrapper {
    margin: 0;
    padding: 0;
    width: 110px;
    height: 32px;
}

.menu-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.menu-item {
    position: relative;
    cursor: pointer;
}

.menu-item:last-child > * {
    padding-right: 0;
}

.menu-link {
    position: relative;
    display: block;
    padding: 28px 24px;
    text-decoration: none;
    font-family: $text-font-family;
    color: transparent;
    text-transform: uppercase;
    cursor: pointer;
}

.menu-link::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    color: $main-text-color;
}

.menu-link:hover::before {
    font-weight: 500;
    color: $main-hover-text-color;
    transition: all 0.3s linear;
}

.menu-item:hover > .menu-link:before {
    font-weight: 500;
    color: $main-hover-text-color;
}

.submenu {
    position: absolute;
    top: calc(100% - 18px);
    left: 50%;
    transform: translateX(-50%);
    display: none;
    width: $submenu-width;
    padding: 16px;
    border: 1px solid $black-color-10-opacity;
    border-radius: $medium-radius;
    background-color: $white-color;
    box-shadow: $box-shadow;

    &::before {
        position: absolute;
        top: -$submenu-arrow-height;
        left: calc(50% - ($submenu-arrow-width / 2));
        width: $submenu-arrow-width;
        height: $submenu-arrow-height;
        background-image: url('/assets/icons/card_arrow_icon.svg');
        background-position: center;
        background-repeat: no-repeat;
        content: '';
    }
}

.menu-item--apps:hover > .submenu {
    display: block;
}

.submenu-list {
    display: grid;
    row-gap: 8px;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.submenu-icon {
    display: block;
    object-fit: contain;
}

.submenu-link {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 8px;
    padding: 17px 24px 15px;
    text-decoration: none;
    font-family: $text-font-family;
    font-size: 16px;
    line-height: 24px;
    color: $main-hover-text-color;
    border-radius: $small-radius;
}

.submenu-link:hover {
    background-color: $grey-light-color;
}
