@use '../../app/App.module.scss' as *;

$section-margin-bottom: 44px;

.main {
    position: relative;
    overflow-x: hidden;
}

.apps-wrapper {
    display: grid;
    row-gap: 44px;
    margin-bottom: $section-margin-bottom;

    @media #{media-query('tablet')} {
        // NOTE: Compensation for intersection observer
        margin-top: 1px;
    }
}

.about-us-wrapper {
    margin-bottom: $section-margin-bottom;

    @media #{media-query('tablet')} {
        margin-bottom: $section-margin-bottom;
    }
}

.main-container {
    position: relative;
    max-width: $max-container-width;
    margin: 0 auto;
    padding-bottom: 44px;
    transition: padding 100ms;

    @media #{media-query('tablet')} {
        padding-bottom: 44px;
        padding-inline: 80px;
    }
}
