@use '../../app/App.module.scss' as *;

.footer-wrapper {
    position: relative;
    height: 465px;
    z-index: $basic-layer + 1;

    @media #{media-query('tablet')} {
        position: static;
    }
}

.footer {
    @include card;

    position: relative;
    height: 465px;

    & {
        padding-top: 72px;
        padding-bottom: 96px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    @media #{media-query('tablet')} {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding-bottom: 144px;
    }
}

.footer-content {
    text-align: center;
}

.logo-wrapper {
    display: block;
    margin-bottom: 48px;
}

.menu-list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 32px;
    justify-content: center;
    align-items: center;
    max-width: 331px;
    margin: 0 auto;
    margin-bottom: 48px;
    padding: 0;
    list-style-type: none;

    @media #{media-query('tablet')} {
        max-width: 696px;
        justify-content: space-between;
    }
}

.menu-item {
    position: relative;
    cursor: pointer;
}

.menu-link {
    position: relative;
    display: block;
    text-decoration: none;
    font-family: $text-font-family;
    color: transparent;
    text-transform: uppercase;
    cursor: pointer;
}

.menu-link::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    color: $main-text-color;
    transition: all 300ms linear;
}

.menu-link:hover::before {
    font-weight: 500;
    color: $main-hover-text-color;
    transition: all 300ms linear;
}

.copyright {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    color: $main-text-color;
    opacity: 0.8;
}